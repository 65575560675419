import React, { useState } from "react"

import * as yup from "yup"
import { Alert,Spin  } from "antd"
import { fetchAPI } from "@/utils/api"

import { Form, Input, InputNumber, Button } from "antd"
import Markdown from "react-markdown"
import VipLeadForm from "./VIPDrivingHobart/VipLeadForm";
const LeadForm = ({ data }) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const [loading, setLoader] = useState(false)
  const environment = process.env.GATSBY_STRAPI_ENV
  const LeadSchema = yup.object().shape({
    email: yup.string().email().required(),
  })

  const onFinish = async values => {

    try {
      setLoader(true)
      await fetchAPI("/lead-form-submissions", {
        method: "POST",
        body: JSON.stringify({
          name: values.user.name,
          email: values.user.email,
          phone: values.user.phone,
          street: values.user.street,
          city: values.user.city,
          state: values.user.state,
          message: values.user.message,
        }),
      })
      setLoader(false)
      setShowFeedback(true)
    } catch (err) {
      setLoader(false)
    }
  }
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  }
  const layout = {
    labelCol: { span: 16 },
    wrapperCol: { span: 16 },
  }

  if (environment == 'vipdrivinghobart') {
    return <VipLeadForm data={data}  />
  }

  return (
    <div className="py-10 text-center">
      <h1 className="text-3xl mb-10 font-bold mb-2">{data.title}</h1>
      <div className="flex flex-col items-center">
        <Form
          className={"lead-form w-10/12"}
          layout={"vertical"}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "name"]}
            label="Name"
            rules={[{ required: true }]}
            className=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["user", "email"]}
            label="Email"
            rules={[{ type: "email", required: true }]}
            className=" "
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["user", "phone"]}
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["user", "street"]} label="Street" className="w">
            <Input />
          </Form.Item>
          <Form.Item name={["user", "city"]} label="City" className="">
            <Input />
          </Form.Item>
          <Form.Item name={["user", "state"]} label="State" className="">
            <Input />
          </Form.Item>
          <Form.Item name={["user", "message"]} label="Message" className="">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className=" flex-col sm:flex-row">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        {loading && (
          <Spin tip="Loading...">
            <Alert
              message="Please wait.."
              description="Your information is being saved."
              type="info"
            />
          </Spin>
        )}
        {showFeedback && (
          <Alert
            message="Thank you"
            description={<div dangerouslySetInnerHTML={{ __html: data.feedbackmessage }} />}
            type="info"
          />
        )}
      </div>
    </div>
  )
}

export default LeadForm
