import React from "react"
import FeebackForm from './FeebackForm'
import './index.less'
const GoogleMap = ({data}) => {


    const {iframeURL,showFeedbackForm} = data;
  return (
    <div className="st-google-map flex sm:flex-col flex-wrap">
        {
            showFeedbackForm &&      <div className="google-map-form flex-1 p-6">
                <FeebackForm/>
            </div>
        }

        <div className=" google-map-iframe flex-1 p-6">
            <iframe
                src={iframeURL}
                    style={{
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
                allowFullScreen
            />
        </div>
    </div>
  );
};



export default GoogleMap;
