import React, { useState } from 'react'
import { Button } from 'antd'
import { Select, Alert } from 'antd'
import { Card } from 'antd'
import NormalLesson from './NormalLesson.json'
import { Col, Row } from 'antd'
import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

const { Option } = Select

let LOCATIONS = NormalLesson.automatic
    .map(a => {
        return a.locations.map(l => {
            return {
                key: a.key,
                singlelesson: a.singlelesson,
                twolesson: a.twolesson,
                calendly: a.calendly,
                location: l,
                info: a.info,
            }
        })
    })
    .flat()
const MANUAL_LOCATIONS = NormalLesson.manual.locations
const INSTRUCTORS = NormalLesson.instructor
const MANUAL_INSTRUCTORS = NormalLesson.manual.instructor
const SPECIAL_NEEDS = []
const renderInfoMessage = (message,selectedSpecialNeed) =>{
    
    if(selectedSpecialNeed!='none'){
        return `${message} There is additional $30 for special need.`
    }
    return `${message}`;
    
}
const VIPBooking = ({ data }) => {
    const [selectedMode, setSelectionMode] = useState()
    const [selectedSpecialNeed, setSelectedSpecialNeed] = useState()
    const [carType, setCarType] = useState()
    const [selectedLocation, setLocation] = useState()
    const [lesson, setLesson] = useState()
    const [instructor, setInstructor] = useState()
    const [eventurl, setCalendlyURL] = useState()
    const [manualLocation, setManualLocation] = useState()
    const [manualInfo, setManualInformation] = useState()
    const [autoInfo, setAutoInformation] = useState()
    const onChangeCarType = type => {
        setCarType(type)
        updateInfo({
            carType: type,
            selectedLocation,
            lesson,
            instructor,
            selectedMode,
            manualLocation,
            selectedSpecialNeed
        })
    }
    const onChangeLocationMode = mode => {
        setSelectionMode(mode)
        updateInfo({
            carType,
            selectedLocation,
            lesson,
            instructor,
            selectedMode: mode,
            manualLocation,
            selectedSpecialNeed
        })
    }

    const onChangeSpecialNeed = mode => {
        setSelectedSpecialNeed(mode)
        updateInfo({
            carType,
            selectedLocation,
            lesson,
            instructor,
            selectedMode,
            selectedSpecialNeed: mode,
            manualLocation,
        })
    }
    
    const onChangePickupLocation = (value, option, index) => {
        setLocation(value)
        updateInfo({
            carType,
            selectedLocation: value,
            lesson,
            instructor,
            selectedMode,
            manualLocation,
            selectedSpecialNeed
        })
    }
    const onChangeInstructor = (value, option, index) => {
        console.log(`selected ${value}`, option, index, selectedMode)
        setInstructor(value)
        updateInfo({
            carType,
            selectedLocation,
            lesson,
            instructor: value,
            selectedMode,
            manualLocation,
            selectedSpecialNeed
        })
    }
    const onChangeManualLocation = value => {
        setManualLocation(value)
        updateInfo({
            carType,
            selectedLocation,
            lesson,
            instructor,
            selectedMode,
            manualLocation: value,
            selectedSpecialNeed
        })
    }

    const updateInfo = data => {
        console.log(data)
        if (
            data?.carType == 'automatic' &&
            (data?.selectedLocation || data.selectedMode == 'office') &&
            data?.instructor &&
            data?.selectedMode &&
            data?.selectedSpecialNeed
        ) {
            console.log(LOCATIONS, 'location')
            const checkLocation =
                data.selectedMode == 'office'
                    ? 'VIP Office'
                    : data.selectedLocation
            const location = LOCATIONS.find(l => l.location == checkLocation)
            console.log(location)
            let specialneed = "none";
            if(data.selectedSpecialNeed!=specialneed){
                specialneed = "special";
            }
            const eventurl = location && location.calendly[data.instructor][specialneed]
            setCalendlyURL(eventurl)
            setAutoInformation(location)
        }
        if (
            data?.carType == 'manual' &&
            data?.instructor &&
            data?.manualLocation
        ) {
            const location = MANUAL_LOCATIONS.find(
                l => l.key == data.manualLocation,
            )
            console.log(location)
            const eventurl = location && location.calendly[data.instructor]
            setManualInformation(location)
            setCalendlyURL(eventurl)
        }
    }
    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => console.log(e),
    })

    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-5 text-center'}> Book an appointment</h2>
            <Row>
                <Col span={6}>
                    <p>Do you want automatic car or manual car ?</p>
                </Col>
                <Col span={12}>

                    <Select
                        placeholder={'Select auto or manual'}
                        onChange={onChangeCarType}
                        style={{ width: '100%' }}
                    >
                        <Option value="select one">Select one</Option>
                        <Option value="automatic">Automatic</Option>
                        <Option value="manual">Manual</Option>
                    </Select>
                </Col>
            </Row>

            {carType && carType == 'manual' && (
                <Row>
                    <Col span={6}>
                        <p>Please Select Location:</p>
                    </Col>
                    <Col span={12}>
                        <Select
                            placeholder={'Select Location'}
                            style={{ width: '100%' }}
                            onChange={onChangeManualLocation}
                        >
                            <Option value="">Select one</Option>
                            {MANUAL_LOCATIONS.map(l => {
                                return <Option value={l.key}>{l.label}</Option>
                            })}
                        </Select>
                    </Col>
                </Row>
            )}

            {carType && carType == 'automatic' && (
                <Row>
                    <Col span={6}>
                        <p>Please Select Location:</p>
                    </Col>
                    <Col span={12}>
                        <Select
                            placeholder={'Select Location'}
                            style={{ width: '100%' }}
                            onChange={onChangeLocationMode}
                        >
                            <Option value="">Select one</Option>
                            <Option value="office">VIP Office</Option>
                            <Option value="pickup">Your Pick-up Location</Option>
                        </Select>
                    </Col>
                </Row>
            )}
            {carType && carType == 'automatic' && (
                <Row>
                    <Col span={6}>
                        <p>Please Select Special Needs:</p>
                    </Col>
                    <Col span={12}>
                        <Select
                            placeholder={'Select Special Needs'}
                            style={{ width: '100%' }}
                            onChange={onChangeSpecialNeed}
                        >
                            <Option value="">Select one</Option>
                            <Option value="none">None</Option>
                            {/*<Option value="limited">Limited/ no English</Option>*/}
                            {/*<Option value="anxiety">Anxiety</Option>*/}
                            {/*<Option value="autism">Autism</Option>*/}
                            {/*<Option value="depression">Depression</Option>*/}
                            {/*<Option value="adhd">ADHD</Option>*/}
                        </Select>
                    </Col>
                </Row>
            )}
            {carType && (
                <Row>
                    <Col span={6}>
                        <p>{carType == 'manual'?"Please Select Car:":"Please Select Instructor:"}</p>
                    </Col>
                    <Col span={12}>
                        <Select
                            placeholder={'Select Instructor'}
                            style={{ width: '100%' }}
                            onChange={onChangeInstructor}
                        >
                            <Option value="selectone">Select one</Option>
                            {carType == 'automatic' &&
                                INSTRUCTORS.map(i => {
                                    return (
                                        <Option key={i.key} value={i.key}>
                                            {i.label}
                                        </Option>
                                    )
                                })}
                               {carType == 'manual' &&  <Option key={'learner-car'} value={'learner-car'}>
                                            Learner Car
                                        </Option>}
                            {/*{carType == 'manual' &&*/}
                            {/*    MANUAL_INSTRUCTORS.map(i => {*/}
                            {/*        return (*/}
                            {/*            <Option key={i.key} value={i.key}>*/}
                            {/*                {i.label}*/}
                            {/*            </Option>*/}
                            {/*        )*/}
                            {/*    })}*/}
                        </Select>
                    </Col>
                </Row>
            )}

            {carType == 'automatic' &&
                selectedMode == 'pickup' &&
                instructor &&
                instructor != 'selectone' && (
                    <Row>
                        <Col span={6}>
                            <p>Please Select Pickup Location:</p>
                        </Col>
                        <Col span={12}>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select Pickup Location"
                                optionFilterProp="children"
                                onChange={onChangePickupLocation}
                            >
                                <Option value="select one">Select one</Option>
                                {LOCATIONS.map(p => {
                                    return (
                                        <Option
                                            key={p.location}
                                            value={p.location}
                                        >
                                            {p.location}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                )}
            {carType == 'automatic' && (
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        {autoInfo && autoInfo?.info && (
                            <Alert
                                className={'m-3'}
                                message={renderInfoMessage(autoInfo.info,selectedSpecialNeed)}
                                type="info"
                            />
                        )}
                    </Col>
                </Row>
            )}

            {carType == 'manual' && (
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        {manualInfo && manualInfo?.info && (
                            <Alert
                                className={'m-3'}
                                message={`${manualInfo.info}`}
                                type="info"
                            />
                        )}
                    </Col>
                </Row>
            )}
            {eventurl && (
                <InlineWidget
                    url={eventurl}
                    styles={{
                        height: '800px',
                        width: '100%',
                    }}
                />
            )}
        </div>
    )
}

export default VIPBooking
