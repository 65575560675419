import React, { useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { Button } from 'antd'
import Lightbox from 'lightbox-react'
import { getStrapiMedia } from '@/utils/media'
import {parseCurrencyString} from "../../utils/ParseCurrencyString";
const cardStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  padding: '1rem',
  marginBottom: '1rem',
  boxShadow: '5px 5px 25px 0 rgba(46,61,73,.2)',
  backgroundColor: '#fff',
  borderRadius: '6px',
  maxWidth: '300px',
  margin: '5px'
}

const ProductCard = ({ product }) => {
  const [isOpen, setOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const { addItem, incrementItem } = useShoppingCart()
  let images = []
  let productThumbnail = product.image
  if (
    product.productImages &&
    Array.isArray(product.productImages) &&
    product.productImages.length > 0
  ) {
    images = product.productImages.map(pi => getStrapiMedia(pi.image.url))
    productThumbnail = images[0]
  }


  return (
    <div style={cardStyles}>
        <div className={"cursor-pointer"} onClick={() => setOpen(true)}>
            <img src={productThumbnail} alt="" />
        </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
      <h4>{product.name}</h4>
      <p>
        Price:{' '}
        {parseCurrencyString(parseInt(product.price),product.currency)}
      </p>
      <Button
        type="primary"
        onClick={() => {
          let cartValues = ''
          if (typeof window !== 'undefined') {
            cartValues = window.localStorage.getItem('cart-values')
          }

          if (cartValues) {
            cartValues = JSON.parse(cartValues)
            if (cartValues.cartDetails && cartValues.cartDetails[product.sku]) {
              incrementItem(product.sku, 1)
            } else {
              addItem(product, 1)
            }
          } else {
            addItem(product, 1)
          }

          navigate(`/checkout`)
        }}
      >
        ADD TO CART
      </Button>
    </div>
  )
}

export default ProductCard
