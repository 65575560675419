import React, { useState } from 'react'
import { Select, Alert } from 'antd'
import { Col, Row } from 'antd'
import Keys2Drive from './Keys2Drive.json'

import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

const { Option } = Select



const INSTRUCTORS = Keys2Drive.instructor

const VIPKeys2DriveBooking = ({ data }) => {
    const [instructor, setInstructor] = useState()
    const [eventurl, setCalendlyURL] = useState()
    const [infomation, setInformation] = useState()

    const onChangeInstructor = (value, option, index) => {
        setInstructor(value)
        updateInfo({
            instructor: value,
        })
    }
    const updateInfo = data => {
        console.log('clisn', data)
        if (data?.instructor) {
            const instructor = INSTRUCTORS.find(l => l.key == data.instructor)
            console.log(instructor, 'INSTRUCTORS')
            if (instructor && instructor?.calendly) {
                setCalendlyURL(instructor.calendly)
            }
        }
    }

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => console.log(e),
    })

    console.log( eventurl, 'rend', infomation)
    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-3 text-center'}>Unfortunately Keys2Drive and Plates Plus program has been suspended by Government since March 31st. 
                Please check other lessons. Thank you.</h2>

            {/*<Row>*/}
            {/*    <Col span={6}>*/}
            {/*        <p>Please Select Instructor:</p>*/}
            {/*    </Col>*/}
            {/*    <Col span={12}>*/}
            {/*        <Select*/}
            {/*            placeholder={'Select Instructor'}*/}
            {/*            defaultValue=""*/}
            {/*            style={{ width: '100%' }}*/}
            {/*            onChange={onChangeInstructor}*/}
            {/*        >*/}
            {/*            <Option value="">Select one</Option>*/}
            {/*            {INSTRUCTORS.map(i => {*/}
            {/*                return (*/}
            {/*                    <Option key={i.key} value={i.key}>*/}
            {/*                        {i.label}*/}
            {/*                    </Option>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </Select>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*{eventurl && (*/}
            {/*    <InlineWidget*/}
            {/*        url={eventurl}*/}
            {/*        styles={{*/}
            {/*            height: '800px',*/}
            {/*            width: '100%',*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    )
}

export default VIPKeys2DriveBooking
