import React from "react"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import ProductPackageItem from "../elements/ProductPackageItem"
import "./ProductPackages.less"
import Markdown from "react-markdown"
import {CartProvider} from "use-shopping-cart";
import Products from "../Products/Products";
import {loadStripe} from "@stripe/stripe-js";
const stripePromise = loadStripe('pk_test_BF19fSOgt65djfwhOUdR5fRQ00A1m0FBoD')

const url = typeof window !== 'undefined' ? window.location.origin : null
const ProductPackages = ({ data }) => {

  const { content, producutpackageitems, heading } = data
  return (
    <div className={"st-product-packages-block flex flex-col text-center"}>
        {heading && <h1 className={"text-5xl"}>{heading}</h1>}
        {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      <CartProvider
          mode="client-only"
          stripe={stripePromise}
          successUrl={`${url}/page-2/`}
          cancelUrl={`${url}/`}
          currency="USD"
          allowedCountries={['US', 'GB', 'CA']}
          billingAddressCollection={true}
      >
          <div className={`st-product-packages-blocks flex flex-wrap flex-1 m-auto`}>
              {producutpackageitems &&
              Array.isArray(producutpackageitems) &&
              producutpackageitems.map(cardItem => {
                  return <ProductPackageItem data={cardItem} />
              })}
          </div>

      </CartProvider>
    </div>
  )
}

export default ProductPackages
