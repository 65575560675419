import React, { useState } from 'react'

import { Alert, Spin } from 'antd'
import { fetchAPI } from '@/utils/api'
import { Form, Input, InputNumber, Button, Select } from 'antd'
import CartOverview from '../Products/CartOverview'
import CartCheckout from '../Products/CartCheckout'
import { CartProvider, useShoppingCart } from 'use-shopping-cart'
import { loadStripe } from '@stripe/stripe-js'
import './DrivingLessonBooking.less'
import { navigate } from 'gatsby'
import PaymentSuccess from './PaymentSucess'
import {parseCurrencyString} from "../../utils/ParseCurrencyString";
const stripePromise = loadStripe('pk_test_BF19fSOgt65djfwhOUdR5fRQ00A1m0FBoD')
const { Option } = Select
const formatCurrencyString = ({ value, currency, language = 'en-AU' }) => {
  value = parseInt(value)
  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol'
  })
  const parts = numberFormat.formatToParts(value)
  let zeroDecimalCurrency = true

  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
      break
    }
  }

  value = zeroDecimalCurrency ? value : value / 100
  return numberFormat.format(value.toFixed(2))
}
const DrivingLessonBooking = ({ data }) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const [showError, setError] = useState(false)
  const [loading, setLoader] = useState(false)
  const [route, setRoute] = useState()
  const [lessonPackage, setLessonPackage] = useState()
  const [lessonPackagePrice, setLessonPackagePrice] = useState()
  const [lessonPackagePriceMessage, setLessonPackagePriceMessage] = useState()
  const [totalAmount, setTotalPrice] = useState('')
  let cartValues = ''
  let isPaymentSuccess =false
  if (typeof window !== 'undefined') {
    cartValues = window.localStorage.getItem('cart-values')
    isPaymentSuccess  = window.location.search.indexOf('session_id')>-1

  }

  let totalPrice = ''
  if (cartValues) {
    cartValues = JSON.parse(cartValues)
    totalPrice = cartValues?.totalPrice
  }
  console.log(
    formatCurrencyString({
      value: 212300,
      currency: 'AUD',
      language: 'en-AU'
    })
  )
  const onFinish = async values => {
    let cartValues = ''
    if (typeof window !== 'undefined') {
      cartValues = window.localStorage.getItem('cart-values')
    }    if (cartValues) {
      cartValues = JSON.parse(cartValues)
    }
    console.log(lessonPackagePrice, 'ss', values)
    let price = ''
    if (cartValues?.totalPrice) {
      const price = parseCurrencyString(     lessonPackagePrice + cartValues.totalPrice,
         'AUD',
        'en-AU')
      console.log(price, 'prics')
      const url = typeof window !== 'undefined' ? window.location.origin : null
      try {
        setLoader(true)
        const response = await fetchAPI('/driving-lesson-bookings', {
          method: 'POST',
          body: JSON.stringify({
            name: values.user.name,
            email: values.user.email,
            phone: values.user.phone,
            address: values.user.address,
            message: values.user.message,
            licensenumber: values.user.licensenumber,
            route: values.user.route,
            package: '',
            totalprice: price,
            emailconfirmation: data.emailconfirmation,
            totalAmount: 100 *( lessonPackagePrice + cartValues.totalPrice),
            success_url: `${window.location.href}`,
            cancel_url: `${url}/lesson-packages`
          })
        })

        // Redirect to Checkout.
        const stripe = await stripePromise
        const responseCheckout = await stripe.redirectToCheckout({
          sessionId: response.id
        })
      } catch (err) {
        console.log(err)
        setLoader(false)
        setError(true)
      }
    }
  }

  const handleChange = value => {
    if (value) {
      let packagedata = data.lessonpackages.filter(lp => lp.route == value)
      if (packagedata && Array.isArray(packagedata) && packagedata.length) {
        const packageObject = packagedata[0].packages.find(rp => rp.label == value)
        console.log(packageObject, 'po')
        setLessonPackagePrice(packageObject.price)
        setLessonPackagePriceMessage(packageObject.message)
        let cartValues = ''
        if (typeof window !== 'undefined') {
          cartValues = window.localStorage.getItem('cart-values')
        }        if (cartValues) {
          cartValues = JSON.parse(cartValues)
        }
        if (cartValues?.totalPrice) {
          const price = parseCurrencyString(      packageObject.price + cartValues.totalPrice,
             'AUD',
             'en-AU')
          console.log(price, 'prics', lessonPackagePrice)
          setTotalPrice(price)
        }
      }
    }
  }

  const handlePackageChange = value => {
    if (value) {
      setLessonPackage(value)
      const packageObject = route.packages.find(rp => rp.label == value)
      if (packageObject && packageObject.price) {
        setLessonPackagePrice(packageObject.price)
        setLessonPackagePriceMessage(packageObject.message)
      }
    }
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
  }
  const layout = {
    labelCol: { span: 16 },
    wrapperCol: { span: 16 }
  }

  const renderPackage = () => {
    if (route && route.packages) {
      return (
        <Form.Item
          name={['user', 'package']}
          label="Package"
          className=""
          rules={[{ required: true, message: 'Please select package' }]}
        >
          <Select defaultValue={'Select your preferred package'} onChange={handlePackageChange}>
            {route.packages.map(pd => {
              return <Option value={pd.label}>{pd.label}</Option>
            })}
          </Select>
        </Form.Item>
      )
    }
  }
  const callback = count => {
    // do something with value in parent component, like save to state

    navigate(`/lesson-packages`)
  }
console.log(data.paymentsuccessmessage,'s')
  if (isPaymentSuccess && data?.paymentsuccessmessage) {
    return <PaymentSuccess message={data.paymentsuccessmessage} />
  }

  return (
    <div className="py-10 text-center driving-lesson-booking m-auto max-w-4xl">
      <h1 className="text-3xl mb-10 font-bold mb-2">{data.title}</h1>
      <CartCheckout parentCallback={callback} />

      <div className="flex flex-col items-center">
        <Form
          className={'lead-form w-full'}
          layout={'vertical'}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item name={['user', 'name']} label="Name" rules={[{ required: true }]} className="">
            <Input />
          </Form.Item>
          <Form.Item
            name={['user', 'email']}
            label="Email"
            rules={[{ type: 'email', required: true }]}
            className=" "
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['user', 'phone']}
            label="Phone Number"
            rules={[{ required: true, message: 'Please input your phone number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={['user', 'licensenumber']} label="Drivers Licence Number">
            <Input />
          </Form.Item>
          <Form.Item name={['user', 'address']} label="Address" className="w">
            <Input />
          </Form.Item>
          {data.lessonpackages && Array.isArray(data.lessonpackages) && (
            <Form.Item
              name={['user', 'route']}
              label="Pick-up Location"
              className=""
              rules={[{ required: true, message: 'Please select route' }]}
            >
              <Select defaultValue={'Select your pick-up location'} onChange={handleChange}>
                {data.lessonpackages.map(lp => {
                  return <Option value={lp.route}>{lp.route}</Option>
                })}
              </Select>
            </Form.Item>
          )}
          {lessonPackagePriceMessage && (
            <Form.Item>
              <div>{`${lessonPackagePriceMessage}`}</div>
            </Form.Item>
          )}

          <Form.Item name={['user', 'message']} label="Message" className="">
            <Input.TextArea />
          </Form.Item>
          {totalAmount && (
            <Form.Item label="" name="totalprice">
              <h2>{`Total Price: ${totalAmount}`}</h2>
            </Form.Item>
          )}
          <Form.Item className=" flex-col sm:flex-row">
            <Button
              className={'w-full '}
              type={'primary'}
              disabled={loading}
              size={'large'}
              htmlType="submit"
            >
              {loading ? 'Loading...' : 'Checkout'}
            </Button>
          </Form.Item>
        </Form>
        {/*{loading && (*/}
        {/*  <Spin tip="Loading...">*/}
        {/*    <Alert*/}
        {/*      message="Please wait.."*/}
        {/*      description="Your information is being saved."*/}
        {/*      type="info"*/}
        {/*    />*/}
        {/*  </Spin>*/}
        {/*)}*/}
        {showFeedback && (
          <Alert
            message="Thank you"
            description={<div dangerouslySetInnerHTML={{ __html: data.feedbackmessage }} />}
            type="info"
          />
        )}
        {showError && (
          <Alert
            message="Error occurred"
            type="error"
            description={<div> Something went wrong. Please correct your information above.</div>}
          />
        )}
      </div>
    </div>
  )
}

export default DrivingLessonBooking
