import React, { useState } from "react"
import { navigate } from "gatsby"
import { useShoppingCart, formatCurrencyString } from "use-shopping-cart"
import { Button } from "antd"
import { DeleteTwoTone, PlusOutlined, MinusOutlined } from "@ant-design/icons"
import "./ProductCardCheckout.less"
import {parseCurrencyString} from "../../utils/ParseCurrencyString";
const cardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
  padding: "1rem",
  marginBottom: "1rem",
  boxShadow: "5px 5px 25px 0 rgba(46,61,73,.2)",
  backgroundColor: "#fff",
  borderRadius: "6px",
  maxWidth: "300px",
  margin: "5px",
}

const ProductCardCheckout = ({ product, removeProductCallback }) => {
  const [qty, setCount] = useState(0)

  const {
    addItem,
    removeItem,
    cartDetails,
    incrementItem,
    decrementItem,
  } = useShoppingCart()

  let cartValues = ""
  if (typeof window !== "undefined") {
    cartValues = window.localStorage.getItem("cart-values")
  }


  let initialNumber = 0
  if (cartValues) {
    cartValues = JSON.parse(cartValues)
    initialNumber =
      cartValues.cartDetails &&
      cartValues.cartDetails[product.sku] &&
      cartValues.cartDetails[product.sku]["quantity"]
    if (!qty && initialNumber) {
      setCount(initialNumber)
    }
  }

  if (!qty && !initialNumber) {
    return null
  }
  return (
    <div className={"st-item-line flex justify-between"}>
      <div className={"st-item-info flex"}>
        <img className={"checkout-image"} src={product.image} alt="" />
        <h2 className="px-4">{product.name}</h2>
      </div>
      <div className={"st-item-qty flex flex-col"}>
        <div className={"flex justify-end p-3"}>
          <DeleteTwoTone
            onClick={() => {
              removeItem(product.sku)
              removeProductCallback(product)
            }}
            twoToneColor="red"
            style={{ fontSize: "25px", color: "red" }}
          />
        </div>
        <div
          className={
            "st-item-qty-detail flex w-64 justify-between items-center"
          }
        >
          <div className={" flex flex-col"}>
            <div className={"py-3"}>Quantity</div>
            <div
              className={"st-item-qty-number flex items-center p-3 bg-gray-50"}
            >
              <MinusOutlined
                className={"p-3"}
                onClick={() => {
                  decrementItem(product.sku, 1)

                  setCount(qty - 1)
                  if (qty - 1 == 0) {
                    removeProductCallback(product)
                  }
                }}
              />
              <div className={"p-3"}>{qty ? qty : initialNumber}</div>
              <div>
                <PlusOutlined
                  className={"p-3"}
                  onClick={() => {
                    incrementItem(product.sku, 1)

                    setCount(qty + 1)
                  }}
                />
              </div>
            </div>
          </div>

          <div className={"font-bold p-3"}>
            <p>
              {parseCurrencyString(   parseInt(product.price), product.currency,)}
            </p>
          </div>
        </div>

        {/*<Button*/}
        {/*  type="primary"*/}
        {/*  onClick={() => {*/}
        {/*    addItem(product)*/}
        {/*    navigate(`/checkout`)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  ADD TO CART*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}

export default ProductCardCheckout
