import React from "react"
import { Carousel } from 'antd';
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};
const MediaCarousel = ({ data }) => {
    const images = data?.images;
    let renderImages = false;
    if(images && Array.isArray(images) && images.length>0) {
        renderImages =true
    }
  return (
    <div className="container">
      <div dangerouslySetInnerHTML={{ __html: data.content }} />
        <div className={"st-media-carousel"}>
            {renderImages && <Carousel dots autoplay>
                {data.images.map(image => {
                    return <div style={contentStyle} className={"st-media-carousel-image-container"}>
                        <img className={"m-auto"} alt={image.image.alt} src={image.image.url}/>
                    </div>

                })}
            </Carousel>
            }
        </div>
    </div>
  );
};
export default MediaCarousel;
