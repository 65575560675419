import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'

const PaymentSuccess = ({message}) => {
  return (
    <div className="prose prose-lg container py-12 sm:p-11 payment-success">
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default PaymentSuccess
