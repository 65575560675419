import React, { useState } from "react"
import {Button} from "antd";
import { useShoppingCart } from "use-shopping-cart"

const buttonStyles = {
  fontSize: "13px",
  textAlign: "center",
  color: "#fff",
  outline: "none",
  padding: "12px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
}

const Cart = () => {
  const [loading, setLoading] = useState(false)
  /* Gets the totalPrice and a method for redirecting to stripe */
  const {
    formattedTotalPrice,
    redirectToCheckout,
    cartCount,
    clearCart,
  } = useShoppingCart()

  return (
    <div className={"flex flex-col justify-end st-cart-overview"}>
    <div className={"w-4/12 ml-auto"}>
        {/* This is where we'll render our cart */}
        <div className={"flex justify-between"}>
            <p>Number of Items: </p>
            <p>{cartCount}</p>
        </div>
        <div className={"flex justify-between"}>
            <p>Total: </p>
            <p>{formattedTotalPrice}</p>
        </div>

        {/* Redirects the user to Stripe */}
            <Button
                className={"w-full "}
                type={"primary"}
                disabled={loading}
                size={"large"}
                onClick={() => {
                    setLoading(true)
                    redirectToCheckout()
                }}
            >
                {loading ? "Loading..." : "Checkout"}
            </Button>

    </div>
    </div>
  )
}

export default Cart
