import React from 'react'
import VIPBooking from './VIPDrivingHobart/VIPBooking'
import VIPThankYou from './VIPDrivingHobart/VIPThankYou'
import VIPSpecializedLessonBooking from './VIPDrivingHobart/VIPSpecializedLessonBooking'
import VIPNightDrivingBooking from './VIPDrivingHobart/VIPNightDrivingBooking'
import VIPKeys2DriveBooking from './VIPDrivingHobart/VIPKeys2DriveBooking'
import VIPP1AssessmentPlaces from './VIPDrivingHobart/VIPP1AssessmentPlaces'
import VIPP1AssessmentBooking from './VIPDrivingHobart/VIPP1AssessmentBooking'
import VIPBuyGiftCard from './VIPDrivingHobart/VIPBuyGiftCard'
import VIPLessonPackages from './VIPDrivingHobart/VIPLessonPackages'
import VIPBuyGiftCardThankYou from './VIPDrivingHobart/VIPBuyGiftCardThankYou'
const CustomSection = ({ data }) => {
    console.log(data)

    if (data && data.reactcomponentname == 'VIPBooking') {
        return <VIPBooking />
    }

    if (data && data.reactcomponentname == 'VIPThankYou') {
        return <VIPThankYou />
    }

    if (data && data.reactcomponentname == 'VIPSpecializedLessonBooking') {
        return <VIPSpecializedLessonBooking />
    }
    if (data && data.reactcomponentname == 'VIPNightDrivingBooking') {
        return <VIPNightDrivingBooking />
    }

    if (data && data.reactcomponentname == 'VIPKeys2DriveBooking') {
        return <VIPKeys2DriveBooking />
    }
    if (data && data.reactcomponentname == 'VIPP1AssessmentPlaces') {
        return <VIPP1AssessmentPlaces />
    }
    if (data && data.reactcomponentname == 'VIPP1Assessment') {
        return <VIPP1AssessmentBooking />
    }
    if (data && data.reactcomponentname == 'VIPBuyGiftCard') {
        return <VIPBuyGiftCard />
    }
        if (data && data.reactcomponentname == 'VIPLessonPackages') {
        return <VIPLessonPackages />
    }
    if (data && data.reactcomponentname == 'VIPBuyGiftCardThankYou') {
        return <VIPBuyGiftCardThankYou />
    }
    return (
        <div className="container py-12 sm:p-11">
            Specify the react component name
        </div>
    )
}

export default CustomSection
