import React from 'react'
import './index.less';
import Image from '../../elements/image';
const Links = ({ data }) => {
  const { links } = data;
  return (
    <div className="st-links-link flex flex-wrap justify-center sm:justify-start">
      {links.map((link, i) => {
        const { backgroundImage, url, text, newTab } = link;
        return (
          <a
            key={i}
            className=" google-map-iframe p-6"
            href={url}
            target={newTab ? '_blank' : ''}
          >
            <Image media={backgroundImage} className="h-56 mb-3 w-auto" />
            <div className={'st-link-title text-lg text-center'}> {text}</div>
          </a>
        );
      })}
    </div>
  );
};

export default Links;
