import React, { useState } from 'react'

import { Select, Alert } from 'antd'

import SpecializedLesson from './SpecializedLesson.json'
import { Col, Row } from 'antd'
import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

const { Option } = Select

let LOCATIONS = SpecializedLesson.locations

const INSTRUCTORS = SpecializedLesson.instructor
const CLIENTTYPE = SpecializedLesson.clientType

const VIPSpecializedLessonBooking = ({ data }) => {
    const [clientType, setClientType] = useState()
    const [location, setLocation] = useState()
    const [price, setPrice] = useState()
    const [instructor, setInstructor] = useState()
    const [eventurl, setCalendlyURL] = useState()
    const [infomation, setInformation] = useState()
    const onChangeClientType = (value, option, index) => {
        setClientType(value)
        updateInfo({
            clientType: value,
            location: location,
            instructor: instructor,
        })
    }

    const onChangeLocation = location => {
        setLocation(location)
        updateInfo({
            clientType: clientType,
            location: location,
            instructor: instructor,
        })
    }
    const onChangeInstructor = (value, option, index) => {
        setInstructor(value)
        updateInfo({
            clientType: clientType,
            location: location,
            instructor: value,
        })
    }
    const updateInfo = data => {
        console.log(clientType, 'clisn', data)
        if (data?.clientType && data?.instructor && data?.location) {
            const location = LOCATIONS.find(l => l.key == data.location)
            console.log(location, 'loca')
            if (location && location?.clientType && location?.calendly) {
                setCalendlyURL(
                    location.calendly[data.instructor][data.clientType],
                )
                setPrice(location.clientType[data.clientType])
                setInformation(location.clientType[data.clientType]['info'])
            }
        }
    }

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => console.log(e),
    })

    console.log(price, eventurl, 'rend')
    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-5 text-center'}>
                Book Specialized Driving Lesson
            </h2>
            <Row>
                <Col span={6}>
                    <p>Please Select Client Type:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Select client type'}
                        style={{ width: '100%' }}
                        onChange={onChangeClientType}
                    >
                        <Option value="">Select one</Option>
                        {CLIENTTYPE.map(l => {
                            return <Option value={l.key}>{l.label}</Option>
                        })}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <p>Please Select Location:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Select Location'}
                        style={{ width: '100%' }}
                        onChange={onChangeLocation}
                    >
                        <Option value="">Select one</Option>
                        {LOCATIONS.map(l => {
                            return <Option value={l.key}>{l.label}</Option>
                        })}
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col span={6}>
                    <p>Please Select Instructor:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Select Instructor'}
                        style={{ width: '100%' }}
                        onChange={onChangeInstructor}
                    >
                        <Option value="">Select one</Option>
                        {INSTRUCTORS.map(i => {
                            return (
                                <Option key={i.key} value={i.key}>
                                    {i.label}
                                </Option>
                            )
                        })}
                    </Select>
                    {infomation && (
                        <Alert
                            className={'m-3'}
                            message={infomation}
                            type="info"
                        />
                    )}
                </Col>
            </Row>


            {instructor && eventurl && (
                <InlineWidget
                    url={eventurl}
                    styles={{
                        height: '800px',
                        width: '100%',
                    }}
                />
            )}
        </div>
    )
}

export default VIPSpecializedLessonBooking
