import React, { useState } from 'react'
import { Select, Alert } from 'antd'
import { Col, Row } from 'antd'
import NightDriving from './NightDriving.json'

import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'

const { Option } = Select

let LOCATIONS = NightDriving.locations

const INSTRUCTORS = NightDriving.instructor

const VIPNightDrivingBooking = ({ data }) => {
    const [location, setLocation] = useState()
    const [price, setPrice] = useState()
    const [instructor, setInstructor] = useState()
    const [eventurl, setCalendlyURL] = useState()
    const [infomation, setInformation] = useState()
    const onChangeLocation = location => {
        setLocation(location)
        updateInfo({
            location: location,
            instructor: instructor,
        })
    }
    const onChangeInstructor = (value, option, index) => {
        setInstructor(value)
        updateInfo({
            location: location,
            instructor: value,
        })
    }
    const updateInfo = data => {
        console.log('clisn', data)
        if (data?.instructor && data?.location) {
            const location = LOCATIONS.find(l => l.key == data.location)
            console.log(location, 'loca')
            if (location && location?.price && location?.calendly) {
                setCalendlyURL(location.calendly[data.instructor])
                setPrice(location.price)
                setInformation(location.info)
            }
        }
    }

    useCalendlyEventListener({
        onProfilePageViewed: () => console.log('onProfilePageViewed'),
        onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
        onEventTypeViewed: () => console.log('onEventTypeViewed'),
        onEventScheduled: e => console.log(e),
    })

    console.log(price, eventurl, 'rend', infomation)
    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-5 text-center'}>Book Night Driving Lesson</h2>
            <Row>
                <Col span={6}>
                    <p>Please Select Location:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Select Location'}
                        style={{ width: '100%' }}
                        onChange={onChangeLocation}
                    >
                        <Option value="">Select one</Option>
                        {LOCATIONS.map(l => {
                            return <Option value={l.key}>{l.label}</Option>
                        })}
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col span={6}>
                    <p>Please Select Instructor:</p>
                </Col>
                <Col span={12}>
                    <Select
                        placeholder={'Select Instructor'}
                        style={{ width: '100%' }}
                        onChange={onChangeInstructor}
                    >
                        <Option value="">Select one</Option>
                        {INSTRUCTORS.map(i => {
                            return (
                                <Option key={i.key} value={i.key}>
                                    {i.label}
                                </Option>
                            )
                        })}
                    </Select>
                </Col>
            </Row>

            {infomation && (
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        <Alert
                            className={'m-3'}
                            message={infomation}
                            type="info"
                        />
                    </Col>
                </Row>
            )}
            {instructor && eventurl && (
                <InlineWidget
                    url={eventurl}
                    styles={{
                        height: '800px',
                        width: '100%',
                    }}
                />
            )}
        </div>
    )
}

export default VIPNightDrivingBooking
