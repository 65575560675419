import React, { useState } from 'react'
import { Button } from 'antd'
import { Select, Alert } from 'antd'
import { Card } from 'antd'

import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
const { Option } = Select

const INSTRUCTORS = ['Mohsin', 'Bikram', 'Jack']


const VIPThankYou = ({ data }) => {


    const url = typeof window !== 'undefined' ? window.location.search : null
    var params1 = new URLSearchParams(url)

    let info = {};
    for (const param of params1) {
        console.log(param)
        info[param[0]] = param[1]
    }


    return (
        <div className="container vip-thankyou py-12 sm:p-11">
           <h5>Your booking Summary</h5>
            <p>You are booked with instructor {info['assigned_to']}</p>
            <p>Your lesson price is {info['event_type_name']}</p>
            <p>Your email is {info['invitee_email']}</p>
            <p>Your lesson date is {info['event_start_time']} to {info['event_end_time']}</p>
        </div>
    )
}

export default VIPThankYou
