import React from "react"
import Hero from "@/components/sections/hero"
import LargeVideo from "@/components/sections/large-video"
import FeatureColumnsGroup from "@/components/sections/feature-columns-group"
import FeatureRowsGroup from "@/components/sections/feature-rows-group"
import BottomActions from "@/components/sections/bottom-actions"
import TestimonialsGroup from "@/components/sections/testimonials-group"
import FullWidthBlock from "@/components/sections/FullWidthBlock"
import RichText from "./sections/rich-text"
import Pricing from "./sections/pricing"
import LeadForm from "./sections/lead-form"
import GoogleMap from "./sections/google-map"
import Links from "./sections/links"
import CardBlocks from "./sections/CardBlocks"
import ProductPackages from "./sections/ProductPackages"
import DrivingLessonBooking from "./sections/DrivingLessonBooking";
import MediaCarousel from "./sections/MediaCarousel";
import ReviewerBlock from "./sections/ReviewerBlock";
import CustomSection from "./sections/CustomSection";
import "./Section.less"
import DrivingLessonBookingType from "./sections/DrivingLessonBookingType";
// Map Strapi sections to section components
const sectionComponents = {
  Strapi_ComponentSectionsHero: Hero,
  Strapi_ComponentSectionsLargeVideo: LargeVideo,
  Strapi_ComponentSectionsFeatureColumnsGroup: FeatureColumnsGroup,
  Strapi_ComponentSectionsFeatureRowsGroup: FeatureRowsGroup,
  Strapi_ComponentSectionsBottomActions: BottomActions,
  Strapi_ComponentSectionsTestimonialsGroup: TestimonialsGroup,
  Strapi_ComponentSectionsRichText: RichText,
  Strapi_ComponentSectionsPricing: Pricing,
  Strapi_ComponentSectionsLeadForm: LeadForm,
  Strapi_ComponentSectionsGoogleMap: GoogleMap,
  Strapi_ComponentSectionsLinksGroup: Links,
  Strapi_ComponentSectionsFullWidthBlock: FullWidthBlock,
  Strapi_ComponentSectionsCardBlocksGroup: CardBlocks,
  Strapi_ComponentSectionsProductPackage: ProductPackages,
  Strapi_ComponentSectionsDrivinglessonbooking: DrivingLessonBookingType,
  Strapi_ComponentSectionsMediaCarousel: MediaCarousel,
  Strapi_ComponentSectionsReviewerBlock: ReviewerBlock,
  Strapi_ComponentSectionsCustomSection: CustomSection,
}

// Display a section individually
const Section = ({ sectionData }) => {

  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__typename]

  if (!SectionComponent) {
    return null
  }

  // Display the section
  return <SectionComponent data={sectionData} />
}

// Display the list of sections
const Sections = ({ sections }) => {

console.log(sections,'section')
  return (
    <div className="flex flex-col">
      {sections.map((section, i) => (
        <section className={`st-section ${section.__typename} ${section.__typename}-${section.id} section-${i} ${section.extraclass}`}>
          <Section
            sectionData={section}
            key={`${section.__component}${(section.id, i)}`}
          />
        </section>
      ))}
    </div>
  )
}

export default Sections
