import React, { useState } from 'react'

import Layout from '@/components/layout'
import SEO from '@/components/seo'
import { CartProvider, useShoppingCart } from 'use-shopping-cart'
import { graphql } from 'gatsby'
import CartOverview from './CartOverview'
import Products from './Products'
import { loadStripe } from '@stripe/stripe-js'
import ProductCardCheckout from './ProductCardCheckout'

const stripePromise = loadStripe('pk_test_BF19fSOgt65djfwhOUdR5fRQ00A1m0FBoD')

const url = typeof window !== 'undefined' ? window.location.origin : null
//https://sad-feynman-e11232.netlify.app/#/cart
const CartCheckout = data => {
  console.log(data,'data')
  const [productItems, setProducts] = useState()
  const seo = { title: 'Products' }

  const formattedTotalPrice = useShoppingCart()
console.log(formattedTotalPrice,'formanted')
  const removeProduct = product => {
    const cartDetails = productItems.cartDetails

    delete cartDetails[product.sku]

    setProducts({ ...productItems, cartDetails: cartDetails })
    if(data?.parentCallback){
      data.parentCallback()
    }
   // data.parentCallback()
  }
  let cartValues = ''
  if (typeof window !== 'undefined') {
    cartValues = window.localStorage.getItem('cart-values')
  }
  if (cartValues && !productItems) {
    if (cartValues && !productItems) {
      setProducts(JSON.parse(cartValues))
    }
  }
  let keys = ''
  if (productItems) {
    keys = Object.keys(productItems.cartDetails)
  }

  return (
    <>
      <CartProvider
        mode="client-only"
        stripe={stripePromise}
        successUrl={`${url}/products`}
        cancelUrl={`${url}/checkout`}
        currency="USD"
        allowedCountries={['AU']}
        billingAddressCollection={true}
      >
        <div className={'st-checkout-item-checkout '}>
          {keys && Array.isArray(keys) && keys.length > 0 && (
            <ul className={'st-checkout-item-list '}>
              {keys.map(key => {
                const value = productItems.cartDetails[key]

                const newProduct = {
                  sku: productItems.cartDetails[key].id,
                  name: productItems.cartDetails[key].name,
                  price: productItems.cartDetails[key].price,
                  currency: productItems.cartDetails[key].currency,
                  image: productItems.cartDetails[key].image
                }
                return (
                  <li className={'shadow-md'}>
                    <ProductCardCheckout
                      key={productItems.cartDetails[key].id}
                      product={newProduct}
                      removeProductCallback={removeProduct}
                    />
                  </li>
                )
              })}
            </ul>
          )}
        </div>
        <CartOverview />
      </CartProvider>
    </>
  )
}

export default CartCheckout
