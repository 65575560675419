import React, { useState } from "react"
import * as yup from "yup"
import { Alert,Spin  } from "antd"
import { fetchAPI } from "@/utils/api"
import { Form, Input, Button } from "antd"
const VipLeadForm = ({ data }) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const [loading, setLoader] = useState(false)
  const environment = process.env.GATSBY_STRAPI_ENV
  const LeadSchema = yup.object().shape({
    email: yup.string().email().required(),
  })

  const onFinish = async values => {

    try {
      setLoader(true)
      await fetchAPI("/lead-form-submissions", {
        method: "POST",
        body: JSON.stringify({
          name: values.user.name,
          email: values.user.email,
          phone: values.user.phone,
          street: values.user.street,
          city: values.user.city,
          state: values.user.state,
          message: values.user.message,
        }),
      })
      setLoader(false)
      setShowFeedback(true)
    } catch (err) {
      setLoader(false)
    }
  }
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
      phone: "${label} is not a validate number!",
    },

  }
  const layout = {
    labelCol: { span: 16 },
    wrapperCol: { span: 16 },
  }
  return (
      <div className="py-10 text-center vip-contact-form">
        <h1 className="text-3xl mb-10 font-bold mb-2 text-left">{data.title}</h1>
        <p className=" text-left">Please send us an enquiry by filling the form below.</p>
        <div className="flex flex-col items-center py-3">
          <Form
              className={"lead-form w-100"}
              layout={"vertical"}
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
          >
            <div className="flex">
              <Form.Item
                  name={["user", "name"]}
                  rules={[{ required: true, }]}
                  className="flex-1 username px-2"
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                  name={["user", "phone"]}
                  className="flex-1 phone"
                  rules={[
                    { required: true,message: "Please input your Phone!" },
                  ]}
              >
                <Input placeholder="Telephone" />
              </Form.Item>

            </div>
            <div className="flex">
              <Form.Item
                  name={["user", "email"]}
                  rules={[{ type: "email", required: true, message: "Please input your Email!"  }]}
                  className="flex-1 px-2 email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item name={["user", "street"]}     className="flex-1 address" >
                <Input placeholder="Address" />
              </Form.Item>

            </div>
            <Form.Item name={["user", "message"]}  className="message">
              <Input.TextArea placeholder="Message" rows={7} />
            </Form.Item>
            <Form.Item className=" flex-col sm:flex-row text-left submit">
              <Button type="primary" htmlType="submit">
                Book Now
              </Button>
            </Form.Item>
          </Form>
          {loading && (
              <Spin tip="Loading...">
                <Alert
                    message="Please wait.."
                    description="Your information is being saved."
                    type="info"
                />
              </Spin>
          )}
          {showFeedback && (
              <Alert
                  message="Thank you"
                  description={<div dangerouslySetInnerHTML={{ __html: data.feedbackmessage }} />}
                  type="info"
              />
          )}
        </div>
      </div>
  )
}

export default VipLeadForm
