import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchAPI } from '@/utils/api'
import { Carousel, Card } from 'antd'
import { Avatar } from 'antd'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const { Meta } = Card
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
}
const ReviewerBlock = ({ data }) => {
    const [loading, setLoader] = useState(false)
    const [reviews, setReviews] = useState([])
    console.log(data)
    // if (!data?.reviews && !data.reviews.length > 0) return null

    useEffect(() => {
        async function fetchReviews() {
            try {
                setLoader(true)
                const response = await fetchAPI(`/reviews`)
                setLoader(false)
                setReviews(response)
            } catch (error) {
                setLoader(false)
            }
        }

        if (data.showall) {
            fetchReviews()
        }
    }, [])
    return (
        <div className="container py-12 sm:p-11">
            <div dangerouslySetInnerHTML={{ __html: data.heading }} />
            {!data.showall &&   <div className={'st-carousel'}>

                    <Carousel dots autoplay>
                        {data.reviews.map(review => {
                            const date = new Date(review.reviewedtime)
                            const year = date.toLocaleString('default', {
                                year: 'numeric',
                            })
                            const month = date.toLocaleString('default', {
                                month: 'long',
                            })
                            const day = date.toLocaleString('default', {
                                day: 'numeric',
                            })
                            const dateString = `${month} ${day}, ${year}`
                            return (
                                <div
                                    style={contentStyle}
                                    className={
                                        'st-media-carousel-image-container'
                                    }
                                >
                                    <div className={'flex align-items-center'}>
                                        <img
                                            className={'p-2'}
                                            src={review.reviewerphoto}
                                        ></img>
                                        <h4 className={'p-2'}>
                                            {review.reviewername}
                                        </h4>
                                    </div>

                                    <div className={'p-2'}>{dateString}</div>
                                    <div className={'p-2'}>
                                        {review.reviewtext}
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>

            </div>}
            {
                loading &&        <div style={{"display":"flex",justifyContent:"center"}}>
                    <Spin tip="Loading...">

                    </Spin>
                </div>
            }
            {!loading && reviews &&
                reviews.length > 0 &&
                reviews.map(review => {
                    const date = new Date(review.reviewedtime)
                    const year = date.toLocaleString('default', {
                        year: 'numeric',
                    })
                    const month = date.toLocaleString('default', {
                        month: 'long',
                    })
                    const day = date.toLocaleString('default', {
                        day: 'numeric',
                    })
                    const dateString = `${month} ${day}, ${year}`
                    return (
                        <div className={'st-review-card-container p-3'}>
                            <Card hoverable={true}>
                                <Meta
                                    avatar={
                                        <Avatar src={review.reviewerphoto} />
                                    }
                                    title={review.reviewername}
                                    description={dateString}
                                />
                                <div className={'p-2'}>{review.reviewtext}</div>
                            </Card>
                        </div>
                    )
                })}
            {!data.showall && (
                <div dangerouslySetInnerHTML={{ __html: data.showmore }} />
            )}
        </div>
    )
}

export default ReviewerBlock
