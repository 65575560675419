import React, { useState } from 'react'
import { Select, Alert } from 'antd'
import { Col, Row } from 'antd'
import Keys2Drive from './Keys2Drive.json'

import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { Button, Checkbox, Form, Input } from 'antd'


import VIPGiftCard from '../../../styles/vipdrivingassets/VIPGiftCard.png'


const VIPBuyGiftCard = ({ data }) => {
    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-5 text-center'}>Save Your Gift Card</h2>
            <img src={VIPGiftCard} alt={'VIPGiftCard'} className={"m-auto"}  style={{height:'400px'}}/>
            <h3 className={"text-center"}><a href={VIPGiftCard} download="VIPGiftCard.jpg"> Download Now</a></h3>
        </div>
    )
}

export default VIPBuyGiftCard
