import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import SEO from '@/components/seo'
import Layout from '@/components/layout'
import Sections from '@/components/sections'
import { withPrefix, Link } from 'gatsby'

const env = process.env.GATSBY_STRAPI_ENV
if (env == 'leadvisa') {
    const less = require('./leadvisa.less')
}

const DynamicPage = ({ data }) => {
    const { contentSections, metadata, slug } = data.strapi.page
    const environment = process.env.GATSBY_STRAPI_ENV
    return (
        <>
            <SEO seo={metadata} />
            <div className={`st-page ${slug} strapi-env-${environment}`}>
                <Layout>
                    <Sections sections={contentSections} />
                </Layout>
            </div>
        </>
    )
}

export default DynamicPage

export const query = graphql`
    query DynamicPageQuery($id: ID!) {
        strapi {
            page(id: $id) {
                slug
                shortName
                metadata {
                    metaTitle
                    metaDescription
                    shareImage {
                        id
                        mime
                        url
                    }
                }
                contentSections {
                    ... on Strapi_ComponentSectionsBottomActions {
                        id
                        title
                        buttons {
                            id
                            newTab
                            text
                            type
                            url
                        }
                    }
                    ... on Strapi_ComponentSectionsHero {
                        id
                        buttons {
                            id
                            newTab
                            text
                            type
                            url
                        }
                        title
                        description
                        label
                        picture {
                            id
                            mime
                            alternativeText
                            url
                        }
                    }
                    ... on Strapi_ComponentSectionsFeatureColumnsGroup {
                        id
                        features {
                            description
                            icon {
                                id
                                mime
                                alternativeText
                                url
                            }
                            id
                            title
                        }
                    }
                    ... on Strapi_ComponentSectionsFeatureRowsGroup {
                        id
                        features {
                            description
                            id
                            link {
                                id
                                newTab
                                text
                                url
                            }
                            media {
                                id
                                mime
                                url
                                alternativeText
                            }
                            title
                        }
                    }
                    ... on Strapi_ComponentSectionsTestimonialsGroup {
                        id
                        description
                        link {
                            id
                            newTab
                            text
                            url
                        }
                        logos {
                            id
                            title
                            logo {
                                id
                                mime
                                alternativeText
                                url
                            }
                        }
                        testimonials {
                            id
                            logo {
                                id
                                mime
                                url
                                alternativeText
                            }
                            picture {
                                id
                                mime
                                url
                                alternativeText
                            }
                            text
                            authorName
                            authorTitle
                            link
                        }
                        title
                    }
                    ... on Strapi_ComponentSectionsLargeVideo {
                        id
                        description
                        title
                        poster {
                            id
                            mime
                            alternativeText
                            url
                        }
                        video {
                            alternativeText
                            url
                        }
                    }
                    ... on Strapi_ComponentSectionsRichText {
                        id
                        content
                        extraclass
                    }
                    ... on Strapi_ComponentSectionsPricing {
                        id
                        title
                        plans {
                            description
                            features {
                                id
                                name
                            }
                            id
                            isRecommended
                            name
                            price
                            pricePeriod
                        }
                    }
                    ... on Strapi_ComponentSectionsLeadForm {
                        id
                        emailPlaceholder
                        title
                        feedbackmessage
                        receipientemails
                    }
                    ... on Strapi_ComponentSectionsGoogleMap {
                        id
                        iframeURL
                        showFeedbackForm
                    }
                    ... on Strapi_ComponentSectionsLinksGroup {
                        id
                        links {
                            backgroundImage {
                                url
                                previewUrl
                                mime
                                alternativeText
                            }
                            id
                            newTab
                            text
                            url
                        }
                    }
                    ... on Strapi_ComponentSectionsFullWidthBlock {
                        id
                        description
                        heading
                        picture {
                            id
                            mime
                            url
                            alternativeText
                        }
                        subheading
                        buttons {
                            id
                            url
                            type
                            text
                            newTab
                        }
                    }
                    ... on Strapi_ComponentSectionsCardBlocksGroup {
                        id
                        cards {
                            buttons {
                                id
                                newTab
                                text
                                type
                                url
                            }
                            description
                            icon {
                                id
                                url
                            }
                            id
                            title
                        }
                        direction
                        heading
                    }
                    ... on Strapi_ComponentSectionsPartnerGroup {
                        id
                        partners {
                            id
                            title
                            icon {
                                id
                                url
                                alternativeText
                            }
                        }
                    }
                    ... on Strapi_ComponentSectionsProductPackage {
                        id
                        heading
                        content
                        producutpackageitems {
                            buttons {
                                id
                                newTab
                                text
                                type
                                url
                            }
                            content
                            heading
                            id
                            price
                            sku
                        }
                    }
                    ... on Strapi_ComponentSectionsDrivinglessonbooking {
                        id
                        lessonpackages {
                            packages
                            route
                            price
                            id
                        }
                        receipientemails
                        title
                        feedbackmessage
                        emailconfirmation
                        emailPlaceholder
                        paymentsuccessmessage
                        type
                    }
                    ... on Strapi_ComponentSectionsMediaCarousel {
                        id
                        content
                        images {
                            id
                            description
                            title
                            shortTitle
                            image {
                                url
                                id
                                caption
                                alternativeText
                                size
                            }
                        }
                    }
                    ... on Strapi_ComponentSectionsCustomSection {
                        id
                        reactcomponentname
                    }
                    ... on Strapi_ComponentSectionsReviewerBlock {
                        id
                        showall
                        heading
                        showmore
                        page {
                            id
                            slug
                        }
                        reviews {
                            id
                            reviewedtime
                            reviewername
                            reviewerphoto
                            reviewtext
                            reviewerpicture {
                                url
                                id
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`
