import React from "react"
import { navigate } from "gatsby"
import { useShoppingCart, formatCurrencyString } from "use-shopping-cart"
import { Button } from "antd"
import { getStrapiURL } from "../../utils/api"
import * as url from "url"
import { getStrapiMedia } from "../../utils/media"
import "./CardItem.less"
import { Card } from "antd"
import Markdown from "react-markdown";

const { Meta } = Card

const CardItem = ({ data }) => {
  let buttons = data?.buttons
  let icon = data?.icon
  let description = data?.description
  let title = data?.title


  let iconUrl = ""
  if (icon && icon.url) {
    iconUrl = getStrapiMedia(icon.url)
  }

  let hasBody = false
  if (title) {
    hasBody = true
  }
  if (description) {
    hasBody = true
  }
  if (buttons.length > 0) {
    hasBody = true
  }
  return (
    <div className={`st-card-item ${hasBody ? "with-body" : "without-body"}`}>
      <Card hoverable cover={<img alt="example" src={iconUrl} />}>
        <Meta title={title} description={<div dangerouslySetInnerHTML={{__html:description}}/>} />
        {buttons &&
          buttons.map(button => {
            return (
              <Button
                className={"my-3"}
                type={button.type}
                onClick={() => {
                  navigate(button.url)
                }}
              >
                {button.text}
              </Button>
            )
          })}
      </Card>
    </div>
  )
}

export default CardItem
