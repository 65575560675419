import React, { useState } from 'react'
import { Select, Alert } from 'antd'
import { Col, Row } from 'antd'
import Keys2Drive from './Keys2Drive.json'

import './VIPBooking.less'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { Button, Checkbox, Form, Input } from 'antd'
import {navigate} from "gatsby";
const { Option } = Select

const INSTRUCTORS = Keys2Drive.instructor
const STRIPE_LINKS = {
    'giftamountlink':'https://buy.stripe.com/eVa5o3cdPeo27x6cMN',
    'automaticdrivinglink':'https://buy.stripe.com/eVa2bRb9LdjY9Fe9AE',
    'manualdrivinglink':'https://buy.stripe.com/aEUaIn3Hj7ZE04EbIN'
}

const VIPBuyGiftCard = ({ data }) => {
    const [instructor, setInstructor] = useState()
    const [eventurl, setCalendlyURL] = useState()
    const [giftType, setGiftType] = useState()

    const onFinish = values => {
        console.log('Success:', values)
        if(values.gifttype=='giftamount'){
            console.log('true')
            navigate(STRIPE_LINKS.giftamountlink)
        }
        if(values.gifttype=='numberoflesson' && values.vehicletransmission=='automatic' ){
            console.log('true')
            navigate(STRIPE_LINKS.automaticdrivinglink)
        }
        if(values.gifttype=='numberoflesson' && values.vehicletransmission=='manual' ){
            console.log('true')
            navigate(STRIPE_LINKS.manualdrivinglink)
        }
    }
    const onChangeGiftType = value => {

        setGiftType(value)
    }
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    return (
        <div className="vip-booking py-12 sm:p-11">
            <h2 className={'p-5 text-center'}>Buy VIP Gift Card</h2>
            <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="Your Name"
                    name="name"
                    rules={[
                        { required: true, message: 'Please input your Name!' },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Your Phone Number:"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your phone number ',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Select Gift Type:"
                    name="gifttype"
                    rules={[
                        {
                            required: true,
                            message: 'Please select gift type  ',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Gift Type"
                        onChange={onChangeGiftType}
                    >
                        <Option value="">Select one</Option>
                        <Option value="giftamount">Gift Amount</Option>
                        {/*<Option value="numberoflesson">Number of Lessons</Option>*/}
                    </Select>
                </Form.Item>
                {/*{giftType && giftType == 'numberoflesson' && (*/}
                {/*    <Form.Item*/}
                {/*        label="Select Lesson Package:"*/}
                {/*        name="lessonpackage"*/}
                {/*        rules={[*/}
                {/*            {*/}
                {/*                required: true,*/}
                {/*                message: 'Select Lesson Package  ',*/}
                {/*            },*/}
                {/*        ]}*/}
                {/*    >*/}
                {/*        <Select placeholder="Select Lesson Package">*/}
                {/*            <Option value="">Select one</Option>*/}
                {/*            <Option value="5">5</Option>*/}
                {/*            <Option value="10">10</Option>*/}
                {/*            <Option value="15">15</Option>*/}
                {/*            <Option value="20">20</Option>*/}
                {/*        </Select>*/}
                {/*    </Form.Item>*/}
                {/*)}*/}
                {giftType && giftType == 'numberoflesson' && (
                    <Form.Item
                        label="Select Vehicle Transmission:"
                        name="vehicletransmission"
                        rules={[
                            {
                                required: true,
                                message: 'Select Vehicle Transmission',
                            },
                        ]}
                    >
                        <Select placeholder="Select Vehicle Transmission">
                            <Option value="">Select one</Option>
                            <Option value="automatic">Automatic</Option>
                            <Option value="manual">Manual</Option>
                        </Select>
                    </Form.Item>
                )}

                <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Buy
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default VIPBuyGiftCard
