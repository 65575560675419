import React, { useState } from 'react'
import './DrivingLessonBooking.less'
import P1AssessmentBooking from './P1AssessmentBooking'
import DrivingLessonBooking from "./DrivingLessonBooking";

const TYPES = {}
const DrivingLessonBookingType = ({ data }) => {
    if (data?.type && data.type == 'P1Assessment')
        return (
            <div
                className={`text-center driving-lesson-booking-${data.type} m-auto max-w-4xl`}
            >
                <P1AssessmentBooking data={data} />
            </div>
        )
    return (
        <DrivingLessonBooking data={data}/>
    )
}

export default DrivingLessonBookingType
