import React from 'react'
import { getStrapiMedia } from '../../utils/media'
import './FullWidthBlock.less'
import ButtonLink from '../elements/button-link'
import { getButtonAppearance } from '../../utils/button'

const FullWidthBlock = ({ data }) => {
  let imageUrl = ''
  if (data && data.picture && data.picture.url) {
    imageUrl = getStrapiMedia(data.picture.url)
  }
  let buttons = ''
  if (data && data.buttons) {
    buttons = data.buttons
  }
  return (
    <div className="full-width-block-container" style={{ backgroundImage: `url(${imageUrl})` }}>
      {data.heading && (
        <div
          className={'full-width-heading sm:text-6xl text-7xl w-auto text-center p-4 inline '}
          dangerouslySetInnerHTML={{ __html: data.heading }}
        />
      )}
      {data.subheading && (
        <div
          className={'full-width-sub-heading mt-8 sm:text-4xl text-5xl w-auto text-center p-4 inline'}
          dangerouslySetInnerHTML={{ __html: data.subheading }}
        />
      )}

      <div className={'full-width-block-buttons mt-8'}>
        {buttons &&
          buttons.map(button => {
            return (
              <ButtonLink
                button={button}
                appearance={getButtonAppearance(button.type, 'dark')}
                key={button.id}
              />
            )
          })}
      </div>
    </div>
  )
}

export default FullWidthBlock
