import React from 'react'
import { navigate } from 'gatsby'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { Button } from 'antd'
import { getStrapiURL } from '../../utils/api'
import * as url from 'url'
import { getStrapiMedia } from '../../utils/media'
import './ProductPackageItem.less'
import { Card } from 'antd'
import Markdown from 'react-markdown'
import { notification, Space } from 'antd'
const { Meta } = Card

const openNotificationWithIcon = type => {
  notification[type]({
    message: 'Cart Updated',
    description: 'Successfully added item you will redirect to booking page now.',
    duration: 3
  })
}
const ProductPackageItem = ({ data }) => {
  const { addItem, incrementItem } = useShoppingCart()
  let buttons = data?.buttons
  let icon = data?.icon
  let content = data?.content
  let title = data?.title

  let iconUrl = ''
  if (icon && icon.url) {
    iconUrl = getStrapiMedia(icon.url)
  }

  let hasBody = false
  if (title) {
    hasBody = true
  }
  if (content) {
    hasBody = true
  }
  if (buttons.length > 0) {
    hasBody = true
  }
  return (
    <div
      className={`st-product-package-item ${hasBody ? 'with-body' : 'without-body'}  ${
        iconUrl ? ' with-icon ' : ' without-icon '
      }`}
    >
      <Card className={'flex '} hoverable cover={iconUrl && <img alt="example" src={iconUrl} />}>
        <Meta title={title} description={<div dangerouslySetInnerHTML={{ __html: content }} />} />
        {buttons &&
          buttons.map(button => {
            return (
              <Button
                className={'my-3'}
                type={button.type}
                onClick={() => {
                  const product = {
                    name: data.heading,
                    sku: data.sku,
                    price: data.price,
                    currency: 'AUD'
                  }
                  addItem(product, 1)
                  openNotificationWithIcon('success')
                  setTimeout(() => {
                    window.open(button.url, '_blank')
                  }, 4000)
                }}
              >
                {button.text}
              </Button>
            )
          })}
      </Card>
    </div>
  )
}

export default ProductPackageItem
