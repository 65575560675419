import React, { useState } from 'react'
import { Form, Input, InputNumber, Button, Alert } from 'antd'
import { fetchAPI } from '@/utils/api'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!'
  },
  number: {
    range: '${label} must be between ${min} and ${max}'
  }
}

const FeedbackForm = () => {
  const [showFeedback, setShowFeedback] = useState(false)
  const [showError, setError] = useState(false)
  const onFinish = async values => {
    console.log(values)
    try {
      await fetchAPI('/lead-form-submissions', {
        method: 'POST',
        body: JSON.stringify({
          name: values.user.name,
          email: values.user.email,
          phone: values.user.contact,
          subject: values.user.subject,
          message: values.user.message
        })
      })
      setShowFeedback(true)
    } catch (err) {
      console.log(err)
      setError(true)
    }
  }

  return (
    <div>
      {showFeedback && <Alert message="Thank you" type="info" />}
      {showError && <Alert message="Error occurred" type="error" />}
      {!showFeedback && (
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={['user', 'name']}
            label="Name"
            rules={[{ required: true }]}
            className=" flex-col sm:flex-row"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['user', 'email']}
            label="Email"
            rules={[{ type: 'email', required: true }]}
            className=" flex-col sm:flex-row"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['user', 'contact']}
            label="Contact No."
            className=" flex-col sm:flex-row"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item name={['user', 'subject']} label="subject" className=" flex-col sm:flex-row">
            <Input />
          </Form.Item>
          <Form.Item name={['user', 'message']} label="Message" className=" flex-col sm:flex-row">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            wrapperCol={{ ...layout.wrapperCol, offset: 8 }}
            className=" flex-col sm:flex-row"
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  )
}

export default FeedbackForm
